<template>
  <div
    id="misc"
    class="ghd-base-color-black h-full"
  >
    <div class="page-title text-center px-5 ghd-text-white">
      <h2 class="text-2xl font-weight-semibold">
        Operazione non permessa
      </h2>
      <p class="text-sm">
        Non hai il permesso per accedere a questa pagina, torna alla dashboard
      </p>

      <div class="misc-character d-flex justify-center">
        <img
          height="auto"
          width="auto"
          :src="appLogo"
        />
      </div>

      <v-btn
        color="primary"
        :to="{name: 'dashboard'}"
        class="mb-4"
      >
        Torna alla dashboard
      </v-btn>
    </div>
  </div>
</template>

<script>

import themeConfig from '@themeConfig'

export default {
  setup() {
    return {
      // themeConfig
      appName: themeConfig.app.name,
      appLogo: themeConfig.app.logo,
    }
  },
}

</script>

<style lang="scss">
@import '@core/preset/preset/misc.scss';
</style>
